import { ref, onMounted } from 'vue'
import { defineStore } from 'pinia'

export const useContactFormStore = defineStore('contactForm', () => {
  const getUserPhone = () => {
    const encodedPhone = localStorage?.getItem('contact-form-phone')
    if (!encodedPhone) return ''
    return atob(encodedPhone)
  }
  const getUserName = () => {
    const encodedName = localStorage?.getItem('contact-form-name')
    if (!encodedName) return ''
    return atob(encodedName)
  }

  const getUserEmail = () => {
    const encodedEmail = localStorage?.getItem('contact-form-email')
    if (!encodedEmail) return ''
    return atob(encodedEmail)
  }
  const getLegalConset = () => {
    const encodedLegal = localStorage?.getItem('contact-form-legal')
    if (!encodedLegal) return false
    return !!encodedLegal
  }

  const getContactFormSubmitted = () => {
    const formSubmitted = localStorage?.getItem('contact-form-submitted')
    if (!formSubmitted) return false
    return !!formSubmitted
  }

  const userName = ref<string>('')
  const userEmail = ref<string>('')
  const userPhone = ref<string>('')
  const legalConsent = ref<Boolean>(false)
  const contactFormSubmitted = ref<Boolean>(false)

  onMounted(() => {
    userPhone.value = getUserPhone()
    userName.value = getUserName()
    userEmail.value = getUserEmail()
    legalConsent.value = getLegalConset()
    contactFormSubmitted.value = getContactFormSubmitted()
  })

  const updateUserPhone = (phone: string, contactFormSubmitted = true) => {
    userPhone.value = phone
    localStorage?.setItem('contact-form-phone', btoa(phone))

    if (contactFormSubmitted) {
      updateContactFormSubmitted(true)
    }
  }

  const clearUserPhone = () => {
    userPhone.value = ''
    localStorage?.removeItem('contact-form-phone')

    updateContactFormSubmitted(false)
  }

  const updateUserName = (name: string) => {
    userName.value = name
    localStorage?.setItem('contact-form-name', btoa(name))
  }

  const clearUserName = () => {
    userName.value = ''
    localStorage?.removeItem('contact-form-name')
  }

  const updateUserEmail = (email: string) => {
    userEmail.value = email
    localStorage?.setItem('contact-form-email', btoa(email))
  }

  const clearUserEmail = () => {
    userEmail.value = ''
    localStorage?.removeItem('contact-form-email')
  }

  const updateLegalConsent = (value: boolean) => {
    legalConsent.value = value
    localStorage?.setItem('contact-form-legal', value ? 'true' : '')
  }

  const updateContactFormSubmitted = (value: boolean) => {
    contactFormSubmitted.value = value
    localStorage?.setItem('contact-form-submitted', value ? 'true' : '')
  }

  return {
    userPhone,
    clearUserPhone,
    updateUserPhone,
    userName,
    clearUserName,
    updateUserName,
    userEmail,
    clearUserEmail,
    updateUserEmail,
    legalConsent,
    updateLegalConsent,
    contactFormSubmitted
  }
})
